import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button,Container,Header,Main,Footer,Tabs,TabPane,Input,Dialog,Checkbox,Card,Carousel,CarouselItem,Menu,MenuItem,Image,Rate,Cascader,TimePicker,
  Select,Option,Radio,Message,Loading,MessageBox,Upload,TimeSelect,Empty,Autocomplete,Skeleton,SkeletonItem,Dropdown,DropdownMenu,DropdownItem } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import mins from "@/api/mins"
import {encode,decode} from "@/util/Base64.js"
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp);

Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Image)
Vue.use(Rate)
Vue.use(Cascader)
Vue.use(TimePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(Upload)
Vue.use(TimeSelect)
Vue.use(Empty)
Vue.use(Autocomplete)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// Vue.use(Loading.service)
Vue.prototype.$encode = encode;
Vue.prototype.$decode = decode;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.config.productionTip = false

// Vue.mixin(mins)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
