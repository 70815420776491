<template>
  <div class="PostFooter">
     <div class="" v-html="GetPlatform.copyright"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name:"PostFooter",
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters(["GetPlatform"])
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.PostFooter{
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}
</style>