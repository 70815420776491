<template>
    <div class="AgreementPop">
        <el-dialog
         :visible.sync="AgreementVisible"
         width="80%"
         center
         top="5vh"
        :before-close="AgreementClose">
        <div class="">
         <div class="f-14" v-if="RichText" v-html="RichText"></div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
export default {
   name:"AgreementPop",
   data(){
    return{
        AgreementVisible:false
    }
   },
   props:{
    RichText:{
        type:String,
        default:""
    }
   },
   methods:{
    AgreementClose(){
      this.AgreementVisible = false
    },
   }
}
</script>

<style lang="scss" scoped>
.AgreementPop{

}
</style>