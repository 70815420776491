import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta:{
      title:"商户首页",
      keepAlive:true
    },
    component: ()=>{return import('@/views/index.vue')}
  },
  {
    path: '/PostIndex',
    name: 'PostIndex',
    meta:{
      title:"帖子信息",
      keepAlive:true
    },
    component: ()=>{return import('@/views/PostIndex.vue')}
  },
  {
    path: '/ShopJoinin',
    name: 'ShopJoinin',
    meta:{
      title:"商户入驻"
    },
    component: ()=>{return import('@/views/ShopJoinin.vue')}
  },
  {
    path: '/ShopDetails',
    name: 'ShopDetails',
    meta:{
      title:"商户详情"
    },
    component: ()=>{return import('@/views/ShopDetails.vue')}
  },
  {
    path: '/PostsDetails',
    name: 'PostsDetails',
    meta:{
      title:"帖子详情"
    },
    component: ()=>{return import('@/views/PostsDetails.vue')}
  },
  {
    path: '/PostsPersonalCenter',
    name: 'PostsPersonalCenter',
    meta:{
      title:"我的帖子"
    },
    component: ()=>{return import('@/views/PostsPersonalCenter.vue')}
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    meta:{
      title:"个人中心"
    },
    component: ()=>{return import('@/views/PersonalCenter.vue')}
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title; // 设置网页标题
  }
  next();
});
export default router
