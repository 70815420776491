import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const localStoragePlugin = store => {
  // 当 store 初始化后调用
  store.subscribe((mutation, state) => {
    // 每次 mutation 之后调用
    // 将状态保存到 localStorage
    localStorage.setItem('state', JSON.stringify(state));
  });
};

const store = new Vuex.Store({
  state: {
     is_login:false,//登录弹窗
     activeName:'0',
     LoginType:1,//是否跳转到注册页面  1是登录页 2是注册页,3是忘记密码
     UsersInfo:{},//用户信息
     RegisterInfo:{},//用户注册设置
     platformInfo:{},// 平台信息
     QrCodeUrl:"",//二维码地址
     PayVisible:false,//二维码弹窗
     AgentParameter:0 //代理
  },
  getters: {
    centerDialogVisible(state){
      return state.is_login
    },
    GetUsers(state){
      return state.UsersInfo
    },
    GetReg(state){
      return state.RegisterInfo
    },
    GetLoginType(state){
      return state.LoginType
    },
    GetPlatform(state){
      return state.platformInfo
    },
    GetAgent(state){
     return state.AgentParameter
    }
  },
  mutations: {
    logopop(state,payload){
      state.is_login = payload
     },
    CkNato(state,val){
      state.activeName = val
    },
    SetUsers(state,val){
      state.UsersInfo = val
    },
    SetReg(state,val){
      state.RegisterInfo = val
    },
    SetLoginType(state,val){
      state.LoginType = val
    },
    SetPlatfrom(state,val){
      state.platformInfo = val
    },
    SetAgent(state,val){
       state.AgentParameter = val
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[localStoragePlugin]
})
// 在页面加载时恢复状态
if (localStorage.getItem('state')) {
  store.replaceState(Object.assign(store.state, JSON.parse(localStorage.getItem('state'))));
}


export default store
