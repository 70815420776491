import {gets,posts} from "@/util/request"

export const Login = (data)=>{
   return posts("&p=member&do=userLogin",data)
}

export const GVC = (data)=>{
    return posts("&do=GVC",{})
}
export const SendOut = (data)=>{
   return posts("&do=PIN",data)
}
export const RegisterS = (data)=>{
    return posts("&p=member&do=register",data)
}
//忘记密码
export const ForgetPwsApi = (data)=>{
    return posts("&p=member&do=checkCode",data)
}

export const ForgetReset = (data)=>{
    return posts("&p=member&do=resetPassword",data)
}