<template>
  <div class="PostHead">
     <div class="PrintHeart dis-flex flex-x-between">
         <div class="PrintHeart-left dis-flex flex-y-center">
            <img :src="GetPlatform.logo" class="m-right20" v-if="GetPlatform.logo"/>
            <div class="">{{ GetPlatform.name }}</div>
         </div>
         <div class="PrintHeart-right dis-flex flex-y-center">
             <div class="NavigationBar dis-flex f-14">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                   <el-tab-pane label="商户首页" name="ShopIndex"></el-tab-pane>
                    <el-tab-pane label="帖子信息" name="PostIndex"></el-tab-pane>
                </el-tabs>
                <!-- <div class="NavigationBar-list col-f4 f-w">
                    首页
                    <div class="NavigationActev"></div>
                </div>
                <div class="NavigationBar-list">信息帖子</div> -->
             </div>
             <div class="UserLogin dis-flex flex-y-center flex-x-end">
                <div class="f-14 col-3 dis-flex flex-y-center" v-if="!GetUsers.mid">
                    你好，
                    <div class="col-f4 m-right10 cur-p" @click.stop="logCk">请登录<span class="iconfont icon-unfold"></span></div>
                    <div class="cur-p" @click.stop="NewUsers">新用户注册</div>
                </div>
                <div class="UserNao dis-flex flex-y-center cur-p" @click="GoUsers" v-else>
                    <img :src="GetUsers.avatar" />
                    <div class="f-14 col-3">{{ GetUsers.nickname }}</div>
                </div>
             </div>
         </div>
     </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import router from '@/router';

export default {
   name:"PostHead",
   data(){
    return{
        platformInfor:{},
        activeName:'/index',
        userinfo:{}
    }
   },
   computed:{
      ...mapGetters(["GetUsers","GetPlatform"])
   },
   created(){
    
    router.afterEach((to, from, failure) => {
        if(to.name == "index" || to.name == "ShopJoinin"){
           this.activeName = "ShopIndex"
        }else if(to.name == "PostIndex"){
          this.activeName = "PostIndex"
        }else{
         this.activeName = "ShopIndex"
        }
        
       })
   },
   watch:{
    // activeName:{
    //     handler(val){
    //       console.log(val);
    //       this.$store.commit("CkNato",val)
    //     },
    //     deep:true
    // }
   },
   methods:{
    ...mapMutations(['logopop']),
    handleClick(e){
     if(e.index == 0){
      this.$router.push("/")
     }else{
        this.$router.push("/PostIndex")
     }
      
    },
    logCk(){
      this.$store.commit("SetLoginType",1)
       this.$store.commit("logopop",true)
    },
    NewUsers(){
      this.$store.commit("SetLoginType",2)
      this.$store.commit("logopop",true)
    },
    GoUsers(){
      this.$router.push("/PersonalCenter")
    }
   }
}
</script>

<style lang="scss" scoped>
.PostHead{
    width: 100%;
    height: 80px;
    background-color: #fff;
    .PrintHeart{
        margin: 0 auto;
        width: 1200px;
        height: 100%;
       .PrintHeart-left{
        font-size: 30px;
         >img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
         }
       }
       .PrintHeart-right{
         .NavigationBar{
           ::v-deep .el-tabs__nav-wrap::after{
             content: "";
             display: block;
             background-color: transparent;
            }
           ::v-deep .el-tabs__header{
                margin: 0;
            }
           ::v-deep .is-active{
             color: #FF4444;
           }
           ::v-deep .el-tabs__active-bar{
            background-color: #FF4444;
           }
           .NavigationBar-list{
            width: 110px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .NavigationActev{
                width: 20px;
                height: 2px;
                background-color: #FF4444;
                position: absolute;
                bottom: 12px;
                left: 50%;
                transform: translateX(-50%);
            }
           }
         }
         .UserLogin{
          width: 200px;
          
           .UserNao{
             >img{
                width: 30px;
                height: 30px;
                border: 1px solid #CCCCCC;
                border-radius: 50%;
                margin-right: 4px;
             }
           }
         }
       }
    }
}
</style>