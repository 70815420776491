import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
let siteroot = window.location.origin;
// console.log(window.location,'获取系统');

let RequestUrl;
let routers = "/addons/weliam_smartcity/core/common/";
const Backstage = "uniapp.php?i=3"+`&aid=${store.getters.GetAgent}`;
if (process.env.NODE_ENV === "development") {
  RequestUrl = "/api" + routers;
} else {
  RequestUrl = siteroot + routers;
}
const request = axios.create({
  baseURL: RequestUrl,
  timeout: 5000,
});

//请求拦截器
request.interceptors.request.use(
  (config) => {
    
    if (config.method == "post") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
request.interceptors.response.use(
  (response) => {
    if (response.status !== 200 || typeof response.data !== "object") {
      Message.error(`网络请求出错:${config.url}`);
      return;
    } else if (response.data.errno == 2) {
      localStorage.removeItem("token");
      store.commit("logopop", true);
      Message.error(response.data.message);
      return;
    } else if (response.data.errno == 1) {
      Message.error(response.data.message);
      return;
    } else if (response.data.errno == 0) {
      return response.data;
    }
  },
  (error) => {
    try {
      Message.error(error?.response?.data?.message || error);
    } catch (err) {
      Message.error(error);
    }
    return Promise.reject(error);
  }
);

export function gets(url, params) {
  let urls = Backstage + url;
  return request.get(urls, {
    params,
  });
}

export function posts(url, data) {
  let urls = Backstage + url;
  if (localStorage.getItem("token")) {
    data.token = localStorage.getItem("token");
  }
  data.source = 5;
  return request.post(urls, data)
  
  
}

export const UploadUrl = (RequestUrl =
  "/api" + routers + Backstage + "&do=uploadFiles");

export function TencentmapGet() {
  let txMapUrl;
  if (process.env.NODE_ENV === "development") {
    txMapUrl = "/txip";
  } else {
    txMapUrl = "https://apis.map.qq.com";
  }
  return axios.get(txMapUrl + `/ws/location/v1/ip?key=${store.state.platformInfo.txmapkey}&output=jsonp&callback=GetLocation`,{})
}

export function TencentmapGeocoder(lat,lng) {
 return posts("&do=Loc2area",{
    lat:lat,
    lng:lng
  })
  // let txMapUrl;
  // if (process.env.NODE_ENV === "development") {
  //   txMapUrl = "/txip";
  // } else {
  //   txMapUrl = "https://apis.map.qq.com";
  // }
  // return axios.get(txMapUrl + `ws/geocoder/v1/?key=${store.state.platformInfo.txmapkey}&location=${lat},${lng}&output=jsonp`,{})
}
export function TencentmapGeocoDer1(address) {
 return posts("&do=Address2area",{
    address
  })
  // let txMapUrl;
  // if (process.env.NODE_ENV === "development") {
  //   txMapUrl = "/txip";
  // } else {
  //   txMapUrl = "https://apis.map.qq.com";
  // }
  // return axios.get(txMapUrl + `ws/geocoder/v1/?address=${address}&key=${store.state.platformInfo.txmapkey}&output=jsonp`,{})
}

export default request;
