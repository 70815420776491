<template>
  <div id="app">
    <Login></Login>
    <!-- <OnlineService></OnlineService> -->
    <layout>
      <template v-slot:header>
           <PostHead></PostHead>
        </template>
        <template v-slot:centen>
          <keep-alive include="index,PostIndex">
            <router-view />
          </keep-alive>
        </template>
        <template v-slot:footer>
           <PostFooter></PostFooter>
        </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/Layout.vue"
import PostHead from "@/components/PostHead.vue"
import PostFooter from "@/components/PostFooter.vue"
import {setSiteInfo,getInt,Client,getImgUrl,getSetInfo,getUsers,GetRegister,GetLocation,AgentApi} from "@/api/BaseIndex"
import Login from "./components/Login.vue"
// import OnlineService from "./components/OnlineService.vue"
export default {
  components:{
    layout,
    PostHead,
    PostFooter,
    Login,
    // OnlineService
  },
  created(){
    setSiteInfo()
    getInt()
    Client()
    getImgUrl()
    getSetInfo()
    getUsers()
    GetRegister()
    GetLocation()
    AgentApi({})
  }
}
// const cesims = document.createElement("script");
//    cesims.type = "text/javascript"
//    cesims.charset = "utf-8"
//    cesims.src = "https://map.qq.com/api/gljs?v=1.exp&key=PBNBZ-GPKWJ-6KDFT-KKMCC-SI7EH-DRFHX"
//    document.head.appendChild(cesims)
</script>

<style lang="scss">
@import url("./css/css.css");
@import url("./css/iconfont.css");
*{
  padding: 0;
  margin: 0;
 
}
</style>
