import {gets,posts,TencentmapGet} from "@/util/request"
import store from "@/store";
import Vue from "vue";
import { Message } from "element-ui";
export const setSiteInfo = ()=>{
   posts("&do=GetPlatformInfor",{})
   .then(res=>{
      store.commit("SetPlatfrom",res.data)
      console.log(res);
     localStorage.setItem("platformInfor",JSON.stringify(res.data)) 
   }).catch(err=>{
     console.log(err);
     
   })
}
export const getInt = ()=>{
   posts("&do=TextSubstitution",{})
   .then(res=>{
     localStorage.setItem("TextSubstitution",JSON.stringify(res.data))
   })
   .catch(err=>{})
}
export const Client = ()=>{
   posts("&do=CustomerService",{})
   .then(res=>{
      localStorage.setItem("CustomerService",JSON.stringify(res.data))
   })
}

export const getImgUrl = ()=>{
   posts("&do=GetimgPrefix",{})
   .then(res=>{
      localStorage.setItem("imgPrefix",JSON.stringify(res.data))
   })
}

export const getSetInfo = ()=>{
   posts("&p=Im&do=getSetInfo",{})
   .then(res=>{
      localStorage.setItem("getSetInfo",JSON.stringify(res.data))
   })
}
export const getUsers = ()=>{
   posts("&p=member&do=memberInfo",{})
   .then(res=>{
      store.commit("SetUsers",res.data)
      localStorage.setItem("userinfo",JSON.stringify(res.data))
   })
}
export const GetRegister = ()=>{
   posts("&p=member&do=getRegisterSet",{})
   .then(res=>{
     store.commit("SetReg",res.data)
   })
}
export const GetLocation = ()=>{
    Vue.prototype.$jsonp(`https://apis.map.qq.com/ws/location/v1/ip?output=jsonp&key=${store.state.platformInfo.txmapkey}`, {dataType: 'jsonp'})
    .then(result =>{
      localStorage.setItem("curLoction",JSON.stringify(result.result))
     
    })
    .catch(error => {
      
    });
    
}

export const AgentApi = (data)=>{
    posts("&do=GetAid",data).then(res=>{
      console.log(res,'33333333');
      localStorage.setItem("agencyData",JSON.stringify(res.data))
   })
}
