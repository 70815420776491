<template>
  <div class="Login">
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="580px"
      center
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="" v-if="GetLoginType == 1">
        <el-tabs v-model="activelogin">
          <el-tab-pane label="手机号登录" name="0">
            <div class="">
              <el-input
                type="text"
                placeholder="请输入账号"
                v-model="Phone"
              ></el-input>
            </div>
            <div class="m-top20 Graphical p-r" v-if="GetReg.img_switch == 1">
              <el-input
                type="text"
                placeholder="请输入验证码"
                v-model="ImgCode"
              ></el-input>
              <el-image :src="ImgUrl" class="cur-p" @click="ImgG()"></el-image>
            </div>
            <div class="m-top20 p-r">
              <el-input
                type="text"
                placeholder="请输入验证码"
                v-model="smsCode"
              >
              </el-input>
              <div
                class="CodeText cur-p"
                :class="{ CodeTextActe: timeCount > 0 }"
                @click="sendSms"
              >
                <span v-if="timeCount > 0">{{ timeEnd }}</span>
                <span v-else>发送验证码</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="1">
            <div class="">
              <el-input
                type="text"
                placeholder="请输入账号"
                v-model="Phone"
              ></el-input>
            </div>
            <div class="m-top30 p-r">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="Password"
              >
              </el-input>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="protocol f-16 m-top30">
          <el-checkbox
            v-model="storeChecked"
            style="margin-right: 5px"
          ></el-checkbox>
          我已阅读并同意<span class="f-w cur-p" @click="AgreementCk(1)">用户协议</span>和<span class="f-w cur-p" @click="AgreementCk(2)">隐私协议</span
          >
        </div>
        <el-button
          :loading="loading"
          type="danger"
          class="LogButtom col-f t-c"
          size="large"
          @click="LoginCks"
        >
          {{ btnText }}
        </el-button>

        <div class="dis-flex flex-y-center flex-x-between m-top30">
          <div class="f-18">
            没有账号？<span class="col-f4 cur-p" @click="RegisterCk(2)"
              >立即注册</span
            >
          </div>
          <div class="f-16 col-f4 cur-p" @click="RegisterCk(3)">忘记密码？</div>
        </div>
      </div>
      <div class="UsersRegister" v-else-if="GetLoginType == 2">
        <div class="f-32 col-3 UsersRegister_Title">立即注册</div>
        <div class="UsersRegister_Inpt">
          <el-input
            type="text"
            placeholder="请输入账号"
            v-model="Phone"
          ></el-input>
        </div>
        <div
          class="UsersRegister_Inpt m-top20 Graphical p-r"
          v-if="GetReg.img_switch == 1"
        >
          <el-input
            type="text"
            placeholder="请输入验证码"
            v-model="ImgCode"
          ></el-input>
          <el-image :src="ImgUrl" class="cur-p" @click="ImgG()"></el-image>
        </div>
        <div class="UsersRegister_Inpt m-top20">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="Password"
          ></el-input>
        </div>
        <div class="UsersRegister_Inpt m-top20">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="passwordAgin"
          ></el-input>
        </div>
        <div
          class="UsersRegister_Inpt m-top20 p-r"
          v-if="GetReg.sms_switch == 0"
        >
          <el-input type="text" placeholder="请输入验证码" v-model="smsCode">
          </el-input>
          <div
            class="CodeText cur-p"
            :class="{ CodeTextActe: timeCount > 0 }"
            @click="sendSms"
          >
            <span v-if="timeCount > 0">{{ timeEnd }}</span>
            <span v-else>发送验证码</span>
          </div>
        </div>
        <div class="protocol f-16 m-top30">
          <el-checkbox
            v-model="storeChecked"
            style="margin-right: 5px"
          ></el-checkbox>
          我已阅读并同意<span class="f-w cur-p" @click="AgreementCk(1)">用户协议</span>和<span class="f-w cur-p"
           @click="AgreementCk(2)" >隐私协议</span
          >
        </div>
        <el-button
          :loading="loading"
          type="danger"
          class="LogButtom col-f t-c"
          size="large"
          @click="RegisterCks"
        >
          {{ RegisterTxet }}
        </el-button>

        <div class="dis-flex flex-y-center flex-x-between m-top30">
          <div class="f-18">
            有账号？<span class="col-f4 cur-p" @click="RegisterCk(1)"
              >立即登录</span
            >
          </div>
        </div>
      </div>
      <div class="UsersRegister" v-else-if="GetLoginType == 3">
        <div class="f-32 col-3 UsersRegister_Title">忘记密码</div>
        <div class="" v-if="!ForgetPsw">
            <div class="UsersRegister_Inpt">
          <el-input
            type="text"
            placeholder="请输入手机号"
            v-model="Phone"
          ></el-input>
        </div>
        <div
          class="UsersRegister_Inpt m-top20 Graphical p-r"
          v-if="GetReg.img_switch == 1"
        >
          <el-input
            type="text"
            placeholder="请输入验证码"
            v-model="ImgCode"
          ></el-input>
          <el-image :src="ImgUrl" class="cur-p" @click="ImgG()"></el-image>
        </div>
        <div class="UsersRegister_Inpt m-top20 p-r">
          <el-input type="text" placeholder="请输入验证码" v-model="smsCode">
          </el-input>
          <div
            class="CodeText cur-p"
            :class="{ CodeTextActe: timeCount > 0 }"
            @click="sendSms"
          >
            <span v-if="timeCount > 0">{{ timeEnd }}</span>
            <span v-else>发送验证码</span>
          </div>
        </div>
        </div>
        <div class="" v-if="!!ForgetPsw">
            <div class="UsersRegister_Inpt m-top20">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="Password"
          ></el-input>
        </div>
        <div class="UsersRegister_Inpt m-top20">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="passwordAgin"
          ></el-input>
        </div>
        <el-button :loading="loading" type="danger" class="LogButtom col-f t-c" size="large" @click="ForgetSuttom">确认</el-button>
        </div>
        <el-button :loading="loading" type="danger" class="LogButtom col-f t-c" size="large" v-if="!ForgetPsw" @click="ForgetNextstep">下一步</el-button>
        <div class="dis-flex flex-y-center flex-x-between m-top30">
          <div class="f-18">
            有账号？<span class="col-f4 cur-p" @click="RegisterCk(1)"
              >立即登录</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
     <AgreementPop ref="AgreementPop" :RichText="Introduction"></AgreementPop>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Login, GVC, SendOut, RegisterS,ForgetPwsApi,ForgetReset } from "@/api/Login";
import AgreementPop from "./AgreementPop.vue";
export default {
  name: "Login",
  data() {
    return {
      activelogin: "0",
      Phone: "",
      smsCode: "",
      storeChecked: false,
      Password: "",
      loading: false,
      ImgCode: "",
      ImgUrl: "",
      codeTime: "",
      getSmsCode: "",
      timeEnd: "",
      timeCount: null,
      RegisterTxet: "注册",
      passwordAgin: "",
      ForgetPsw:false,
      Introduction:""
    };
  },
  components:{
    AgreementPop
  },
  computed: {
    ...mapGetters(["centerDialogVisible", "GetReg", "GetLoginType"]),
    btnText() {
      return this.loading ? "登陆中...." : "登录";
    },
  },
  created() {
    // if(!!this.centerDialogVisible){
    this.ImgG();
    // }
  },
  methods: {
    ...mapMutations(["logopop","SetLoginType"]),
    handleClose() {
        this.loading = false;
      this.phone = "";
      this.Password = "";
      this.smsCode = "";
      this.ImgCode = "";
      this.logopop(false);
    },
    LoginCks() {
      let _this = this;
      if (this.loading) {
        return;
      }
      if (_this.Phone == "" || _this.Phone.length !== 11) {
        this.$message({
          message: "请输入正确格式的手机号",
          type: "warning",
        });
        return;
      }
      if (this.activelogin == 0) {
        if (!this.smsCode) {
          this.$message({
            message: "请输入验证码",
            type: "warning",
          });
          return;
        }
      } else if (this.activelogin == 1) {
        if (_this.Password == "") {
          this.$message({
            message: "密码不能为空",
            type: "warning",
          });
          return;
        }
      }
      if (!_this.storeChecked) {
        this.$message({
          message: "请先阅读用户协议并勾选",
          type: "warning",
        });
        return;
      }
      let data = {
        type: _this.activelogin == 0 ? 2 : 1,
        phone: _this.Phone,
        password: _this.activelogin == 0 ? _this.smsCode : _this.Password,
        backurl: "",
      };
      this.loading = true;
      Login(data)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          setTimeout(() => {
            this.logopop(false);
            this.$router.go(0);
            this.$message.success({
              message: "登录成功!",
              duration: 1500,
            });
            this.loading = false;
          }, 1800);
          console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error(err.message)
        });
    },
    ImgG() {
      GVC().then((res) => {
        this.ImgUrl = res.data.image_data;
        this.codeTime = res.data.time;
      });
    },
    sendSms() {
      let _this = this;
      if (_this.timeCount > 0 || _this.timeCount != null) {
        return;
      }
      if (_this.Phone == "" || _this.Phone.length !== 11) {
        this.$message({
          message: "请输入正确格式的手机号",
          type: "warning",
        });
        _this.ImgG();
        return;
      }
      if (_this.ImgCode == "" && _this.GetReg.img_switch == 1) {
        this.$message({
          message: "验证码不能为空",
          type: "warning",
        });
        _this.ImgG();
        _this.ImgCode = "";
        return;
      }
      let data = {
        type: 2,
        phone: _this.Phone,
        code: _this.ImgCode,
        time: _this.codeTime,
      };
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "发送中..",
        background: "rgba(0, 0, 0, 0.7)",
      });
      SendOut(data)
        .then((res) => {
          let timer_num = 60;
          let timeClock = setInterval(function () {
            timer_num--;
            _this.timeEnd = timer_num + "s后重新获取验证码";
            _this.timeCount = timer_num;
            if (timer_num == 0) {
              clearInterval(timeClock);
              return;
            }
          }, 1000);
          _this.getSmsCode = res.data.code;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    RegisterCk(type) {
      this.loading = false;
      this.phone = "";
      this.Password = "";
      this.smsCode = "";
      this.ImgCode = "";
      this.$store.commit("SetLoginType", type);
    },
    RegisterCks() {
      let _this = this;
      if (this.loading) {
        return;
      }
      if (_this.Phone == "" || _this.Phone.length !== 11) {
        this.$message({
          message: "请输入正确格式的手机号",
          type: "warning",
        });
        return;
      }
      if (_this.GetReg.img_switch == 1) {
        if (this.ImgCode == "") {
          this.$message({
            message: "图形验证码不能为空",
            type: "warning",
          });
        }
      }
      if (_this.Password == "" || _this.passwordAgin == "") {
        this.$message({
          message: "输入的密码不能为空",
          type: "warning",
        });
        return;
      }
      if (_this.Password !== _this.passwordAgin) {
        this.$message({
          message: "两次输入的密码不一致",
          type: "warning",
        });
        return;
      }
      if (this.GetReg.sms_switch == 0) {
        if (this.smsCode == "") {
          this.$message({
            message: "请输入验证码",
            type: "warning",
          });
          return;
        }
      }
      if (!_this.storeChecked) {
        this.$message({
          message: "请先阅读用户协议和隐私协议并勾选",
          type: "warning",
        });
        return;
      }
      if (_this.GetReg.sms_switch == 0) {
        _this.getSmsCode = Number(_this.getSmsCode);
        _this.smsCode = Number(_this.smsCode);
      }
      let data = {
        phone: _this.Phone,
        password: _this.Password,
        code: _this.smsCode,
      };
      this.loading = true;
      RegisterS(data)
        .then((res) => {
          this.$message.success({
            message: "注册成功",
            duration: 1800,
          });
          this.loading = flase;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    ForgetNextstep(){
        let _this=this;
        if(this.loading){
             return
        }
       if(this.Phone == "" || this.Phone.length !== 11){
           this.$message({
             message:"请输入手机号",
             type:"warning"
           })
            return
       }
       if(!this.ImgCode && this.GetReg.img_switch == 1){
        this.$message({
            message:"请输入图形验证码",
            type:"warning"
        })
           return
       }
       if(this.smsCode == ""){
        this.$message({
            message:"请输入验证码",
            type:"warning"
        })
        return
       }
      let data = {
        phone: _this.Phone,
        code: _this.smsCode
      };
      this.loading = true
      ForgetPwsApi(data).then(res=>{
        if(res.errno === 0){
           this.ForgetPsw = true
           this.loading = false
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    ForgetSuttom(){
        let _this=this;
        if(this.loading) return
        if (_this.Password == '' || _this.passwordAgin == '') {
            this.$message({
                message:"密码不能为空",
                type:"warning"
            })
			return;
		}
		if (_this.Password !== _this.passwordAgin) {
            this.$message({
                message:"两次输入的密码不一致",
                type:"warning"
            })
			return;
		}
        let data = {
            phone: _this.Phone,
            password: _this.Password
        };
        this.loading = true
        ForgetReset(data).then(res=>{
            this.$message({
                message:"重置密码成功",
                type:"success"
            })
            setTimeout(() => {
                 this.loading = false
                 this.logopop(flase)
                 this.Phone = "";
                 this.ImgCode = "";
                 this.smsCode = "";
                 this.Password = "";
                 this.passwordAgin = "";
                 this.SetLoginType(1)
            }, 1800);
        }).catch(err=>{
            this.loading = true
        })
    },
    AgreementCk(type){
       if(type == 1){
        this.Introduction = this.GetReg.describe
       }else{
         this.Introduction = this.GetReg.privacy
       }
       this.$refs.AgreementPop.AgreementVisible = true
    }
  },
};
</script>

<style lang="scss" scoped>
.Login {
  ::v-deep .el-dialog {
    border-radius: 20px;
    padding: 30px;
    padding-top: 0;
    box-sizing: border-box;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  ::v-deep .el-tabs__content {
    margin-top: 30px;
    .el-tab-pane {
      .el-input {
        .el-input__inner {
          height: 60px;
          line-height: 60px;
          border-radius: 10px;
          border: 1px solid #ccc;
          font-size: 16px;
        }
      }
    }
  }
  ::v-deep .el-tabs__nav-wrap {
    height: 50px;
    .el-tabs__nav-scroll {
      height: 100%;
      .el-tabs__nav {
        height: 100%;
        .el-tabs__active-bar {
          background-color: #ff4444;
        }
        .el-tabs__item {
          font-size: 20px;
          color: #999;
        }
        .is-active {
          font-size: 25px;
          color: #333;
          font-weight: bold;
        }
      }
    }
  }
  .protocol {
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #ff4444;
      border-color: #ff4444;
    }
  }
  .CodeText {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #ff4444;
    font-size: 18px;
  }
  .CodeTextActe {
    color: #999;
  }
  .Graphical {
    .el-image {
      width: 70px;
      height: 31px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .LogButtom {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: #ff4444;
    font-size: 24px;
    margin-top: 50px;
  }
  .UsersRegister {
    .UsersRegister_Title {
      border-bottom: 1px solid #ccc;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .UsersRegister_Inpt {
      ::v-deep .el-input {
        .el-input__inner {
          height: 60px;
          line-height: 60px;
          border-radius: 10px;
          border: 1px solid #ccc;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
