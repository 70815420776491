<template>
   <div class="layout">
    <el-container>
      <el-header height="auto">
        <slot name="header"></slot>
      </el-header>
      <el-main>
        <slot name="centen"></slot>
      </el-main>
      <el-footer>
        <slot name="footer"></slot>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
   name:"layout",
   data(){
    return{

    }
   },
   methods:{

   }
}
</script>

<style lang="scss" scoped>
.layout{
    .el-header,.el-main,.el-footer{
      padding: 0;
   }
   .el-main{
    min-height: 100vh;
    background-color: #F0F3EF;
   }
}
</style>